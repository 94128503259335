import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { CheckIcon, HandThumbUpIcon, UserIcon, ExclamationTriangleIcon, ExclamationCircleIcon, StopCircleIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { getRoutes } from './api/routes'
import moment from 'moment-timezone'
import Loading from './Loading'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const generateRouteStatus = (route) => {
  // check alerts
  const alert_checkpoint = route?.checkpoints?.filter(checkpoint => checkpoint.checkpoint_type == 'digital_sign')[0];
  const alert = alert_checkpoint?.latest_status?.description;
  if (alert == 'no alerts') {
    // check duration
    return generateStatusFromDuration(route.duration);
  }
  if (alert?.split(' ').includes('closed') || alert?.split(' ').includes('CLOSED')) return 'bad';
  return 'mid';
}

const generateStatusFromDuration = (duration) => {
  if (duration.current <= duration.normal) return 'good';
  const percent = 1 - (Number(duration.normal) / Number(duration.current));
  if (percent < Number(0.25)) return 'good';
  if (percent < Number(0.5)) return 'mid';
  return 'bad';
}

const generateIcon = (status) => {
  switch (status) {
    case 'good':
      return <CheckIcon className="bg-green-500 rounded-full h-4 w-4 text-white" aria-hidden="true" />
    case 'mid':
      return <ExclamationTriangleIcon className="bg-yellow-500 rounded-full h-4 w-4 text-white" aria-hidden="true" />
    case 'bad':
      return <StopCircleIcon className="bg-red-500 h-4 w-4 text-white rounded-full" aria-hidden="true" />
    default:
      return <CheckIcon className="bg-green-500 h-4 w-4 text-white rounded-full" aria-hidden="true" />
  }
}


function App() {
  const [routes, setRoutes] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [tabs, setTabs] = useState(null)
  const [loading, setLoading] = useState(null);
  const fetchRoutes = async () => {
    setLoading(true);
    const response = await getRoutes();
    if (!response?.data) return
    setRoutes([...response?.data]);
    setSelectedRoute({ ...response?.data[0] });
    setTabs([...response?.data.map((route) => { return { name: route.name, id: route._id } })]);
    setLoading(false);
  }
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    fetchRoutes();
  }, [])

  return (
    <>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <p className="text-center mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Utah Ski Roads
          </p>

          <div className="mx-auto max-w-2xl text-center">
            {(loading) && <Loading className="" />}
            {(!loading) && <>
              {routes?.map((route, i) => {
                return <Route route={route} route_idx={i} />
              })}
            </>}
          </div>
        </div >
      </div >
    </>
  );
}

function CheckpointUI({ checkpoint, i }) {
  return (
    checkpoint?.media_type == 'stream' ?
      <div className={classNames('mb-2')} >
        <p className='text-xs relative z-10 font-bold'>{checkpoint?.name}</p>
        <iframe className="w-[275px] h-[200px] md:w-[500px] md:h-[300px]" src={checkpoint?.stream_url} frameborder="0" title="Mountain - Brighton Ski Resort" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
      :
      <div className={classNames(i == 0 ? 'mt-4' : '', 'mb-2')} key={i}>
        <p className='text-xs relative z-10 font-bold'>{checkpoint?.name}</p>
        <img className='-mt-3 z-0' src={checkpoint.media_url} />
      </div>
  )
}

function Route({ route, route_idx }) {
  const [expand, setExpand] = useState(false)
  return (
    <div className='flex flex-col border rounded-md border-grey200 py-8 my-4'>
      <div
        className='flex  px-6 space-x-2 items-center justify-between '
        onClick={() => setExpand(!expand)}
      >
        <div className='flex space-x-2'>
          <div className='pt-1'>{generateIcon(generateRouteStatus(route))}</div>
          <div>
            {route?.name}
          </div>
        </div>
        {
          expand
            ? <ChevronUpIcon className="pt-1  h-6 w-6 text-xl font-bold rounded-full" aria-hidden="true" />
            : <ChevronDownIcon className="pt-1  h-6 w-6 text-xl font-bold rounded-full" aria-hidden="true" />
        }
      </div>
      {expand &&
        <div className={classNames(expand ? 'block' : 'hidden', 'pt-4')}>
          <RouteContent route={route} route_idx={route_idx} />
        </div>
      }
    </div>
  )
}


function RouteContent({ route, route_idx }) {
  const [showAlerts, setShowAlerts] = useState(false);
  const [showRoadCams, setShowRoadCams] = useState(false);
  const [showLotCams, setShowLotCams] = useState(false);
  const [showMtnCams, setShowMtnCams] = useState(false);

  return (
    <div className="mx-12 text-left">
      {route?.duration?.current && <p className='text-xs'>EST. TIME UP CANYON: {formatDuration(route?.duration?.current)}</p>}
      <p className='text-xs'>UPDATED: {moment(
        route?.checkpoints?.filter(checkpoint => checkpoint.checkpoint_type == 'digital_sign')[0]?.latest_status?.date_created).format('MM/DD hh:mm A') || ''}</p>
      <div className='flex flex-col'>
        <div className='flex' onClick={() => setShowAlerts(!showAlerts)}>
          <p className='text-xs my-2 underline'>ALERTS:</p>

        </div>
        {route?.checkpoints?.filter(checkpoint => checkpoint.checkpoint_type == 'digital_sign').map((checkpoint, i) => {
          return (
            <div className={classNames(i == 0 ? 'mt-2' : '', 'mb-2')} key={i}>
              <p className='' >{checkpoint?.latest_status?.description || 'N/A'}</p>
            </div>
          )
        }
        )}
      </div>
      <div className='flex flex-col'>
        <div className='flex' onClick={() => setShowRoadCams(!showRoadCams)}>
          <p className='text-xs underline'>ROAD CAMS:</p>
          {showRoadCams
            ? <ChevronUpIcon className="ml-2  h-4 w-4 text-xl font-bold rounded-full" aria-hidden="true" />
            : <ChevronDownIcon className="ml-2  h-4 w-4 text-xl font-bold rounded-full" aria-hidden="true" />
          }
        </div>
        <div className={classNames(showRoadCams ? 'block' : 'hidden')}>
          {route?.checkpoints?.filter(checkpoint => checkpoint.checkpoint_type == 'road')
            .sort((a, b) => a.order - b.order)
            .map((checkpoint, i) => {
              return (
                <CheckpointUI checkpoint={checkpoint} i={i} />
              )
            })}
        </div>
      </div>
      {
        !!route?.resorts?.length && <>
          <p className='text-xs my-4 underline'>RESORT CAMS:</p>
          <div className='flex flex-col'>
            <div >
              {route?.resorts?.map((resort, i) => {
                return (
                  <ResortUI resort={resort} i={i} />
                )
              })}
            </div>
          </div>
        </>
      }
    </div>
  )
}

function ResortUI({ resort, i }) {
  const [showResortCams, setShowResortCams] = useState(false);
  return (
    <div className='flex flex-col'>
      <div className='flex' onClick={() => setShowResortCams(!showResortCams)}>
        <p className='text-xs'>{resort?.name}</p>
        {showResortCams
          ? <ChevronUpIcon className="ml-2 h-4 w-4 text-xl font-bold rounded-full" aria-hidden="true" />
          : <ChevronDownIcon className="ml-2 h-4 w-4 text-xl font-bold rounded-full" aria-hidden="true" />
        }
      </div>
      <div className={classNames(showResortCams ? 'block' : 'hidden')}>
        {resort?.resort_cams?.map((resortCam, i) => {
          return (
            <ResortCamUI resortCam={resortCam} i={i} />
          )
        })}
      </div>
    </div>
  )
}

function ResortCamUI({ resortCam, i }) {
  const [expand, setExpand] = useState(false);
  return (
    <div className={classNames('mb-2')} >
      {resortCam?.media_type == 'link' ? (
        <a href={resortCam?.media_link} className="text-sm text-blue-500 underline" target="_blank" rel="noopener noreferrer">
          View Cams
        </a>
      ) : (
        <>
          <p className='text-xs relative z-10 font-bold'>{resortCam?.name}</p>

          <iframe
            className="w-[275px] h-[200px] md:w-[500px] md:h-[300px]"
            src={resortCam?.stream_url}
            frameborder="0"
            title={resortCam?.name || 'n/a'}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          />
        </>
      )}
    </div>
  )
}

function formatDuration(seconds) {
  const hours = Math.floor(seconds / 3600); // 3600 seconds in 1 hour
  const minutes = Math.floor((seconds % 3600) / 60); // remainder from hours divided by 60

  if (hours == '0') return `${minutes}m`;
  return `${hours}h ${minutes}m`;
}

function Checkpoint({ checkpoint, checkpoint_length, item_idx }) {
  const { _id, latest_status, name, media_url } = checkpoint;
  const [expand, setExpand] = useState(false);
  return (
    <div className="relative pb-8">
      {item_idx !== checkpoint_length - 1 ? (
        <span className="absolute left-2 top-2 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
      ) : null}
      <div className="relative flex space-x-3">
        <div>
          {generateIcon(latest_status?.status)}
        </div>
        <div className="flex flex-col min-w-0 flex-1 justify-between space-x-4 ">
          <div onClick={() => setExpand(!expand)}>
            <p className="text-sm text-gray-500">
              {name}
            </p>
          </div>
          <div className={
            classNames(expand ? 'flex max-w-50' : 'hidden', 'flex-col flex-shrink-0 self-center',
              '')
          }>
            <img src={media_url} className='max-h-48 w-48' />
            <a href={media_url} className="text-sm text-gray-500">
              link
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}



export default App;

